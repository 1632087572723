<!--
A recursive component to display hierarchy data in a tree view.
@author Brian McKenna
-->
<template>
  <ul>
    <li v-for="node in nodes" :key="node.id">
      <a href="Javascript:void(0)" @click="toggleNode(node.id)" v-if="node.children.length > 0" class="expand">
        <BIconCaretDownFill v-if="showChildren[node.id]" />
        <BIconCaretRightFill v-if="!showChildren[node.id]" />
      </a>
      <span class="expand" v-if="node.children.length == 0">
        &nbsp;
      </span>
      <router-link
        :to="{ name: 'Details', query:{ irn: node.id, catType: node.catType, gdcEvent: 'hierarchy_item_view' } }"
        :class="{ 'font-weight-bold': (node.id==selected) }" class="d-table-cell" :id="node.id">
        {{ node.text }}
      </router-link>
      <div v-show="showChildren[node.id]">
        <Hierarchy :nodes="node.children" :selected="selected" @showNode="showNode(node.id)" />
      </div>
    </li>
  </ul>
</template>

<script>
import { BIconCaretDownFill, BIconCaretRightFill } from 'bootstrap-icons-vue'

export default {
  name: 'Hierarchy',
  props: {
    nodes: Array,
    selected: String
  },
  emits: ['showNode', 'scrollTo'],
  components: { BIconCaretDownFill, BIconCaretRightFill },
  mixins: [],
  data() {
    return {
      showChildren: {}
    }
  },
  methods: {
    // Show/hide the children for a node.
    toggleNode(nodeId) {
      this.showChildren[nodeId] = !this.showChildren[nodeId]
    },
    // Triggered from child node via @showNode event handler above, when it (the child) is being shown.
    showNode(nodeId) {
      this.showChildren[nodeId] = true
      // Continue the "showNode" event handling up the tree.
      this.$emit('showNode')
    }
  },
  updated() {
    for (const node of this.nodes) {
      if (node.root && node.id == this.selected) {
        this.showChildren[node.id] = true
      }
    }
  },
  mounted() {
    // For each sibling node in this component, store a boolean flag for displaying the node's children, if present.
    for (const x in this.nodes) {
      const node = this.nodes[x]

      // Children are processed before parents. A selected child may already have emitted a showNode
      // up to the current node so don't overwrite it with false.
      if (this.showChildren[node.id] == null) {
        // showNode has not been emitted.
        const showChildren = node.id == this.selected

        this.showChildren[node.id] = showChildren

        if (showChildren) {
          // Instigate "showNode" event handling to show all ascendants of the selected node.
          this.$emit('showNode')
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style: none;
  font-size: 14px;
  padding-left: 20px;
}

.expand {
  width: 25px;
  display: table-cell;
  text-align: center;
}
</style>
