<!--
Results screen to display results from landing screen searches and a filter form to filter
the search results.
@author Mark Herraghty, Brian McKenna
-->
<template>
  <Header>
    <div class="flex-grow-1 d-md-none" />
    <button
      id="searchButton"
      aria-label="Show/hide search form"
      class="btn text-white text-right d-md-none"
      @click="offCanvasFormActive = !offCanvasFormActive"
    >
      <BIconFunnelFill />
    </button>
  </Header>
  <main class="container-fluid" style="overflow-x: clip">
    <div class="row row-offcanvas" :class="{ active: offCanvasFormActive }">
      <div class="col-md-4 col-lg-3 col-xl-2 sidebar-offcanvas pt-2">
        <form @submit="filter">
          <div class="form-group">
            <label for="query"><strong>Search again</strong></label>
            <input
              id="query"
              type="text"
              class="form-control"
              v-model="query"
            />
            <div class="d-flex my-2">
              <router-link
                class="btn px-0 text-left"
                :to="{ name: 'Help' }"
                target="_blank"
              >
                <BIconQuestionCircle />
                <span class="align-middle"> Help </span>
              </router-link>
              <div class="flex-grow-1" />
              <input
                type="submit"
                class="btn btn-primary px-4"
                value="Search"
              />
            </div>
          </div>
          <div class="form-check my-2">
            <input
              id="images"
              class="form-check-input"
              type="checkbox"
              value="0"
              v-model="images"
              @change="filter"
            />
            <label class="form-check-label" for="images">
              Only records with images
            </label>
          </div>
          <div class="form-group">
            <label for="groupName"><strong>Filter by</strong></label>
            <select
              id="groupName"
              class="custom-select custom-select-sm"
              v-model="groupName"
              @change="filter"
            >
              <option value="All Fields">All Fields</option>
              <option value="Title">Title</option>
              <option value="Description">Description</option>
              <option value="Person">Person</option>
              <option value="Place_Language">Place</option>
              <option value="Period">Period</option>
              <option value="ReferenceCode">Reference Code</option>
              <option value="Taxonomy">Taxonomy</option>
            </select>
          </div>
          <div class="form-group">
            <label for="collection"><strong>Collection</strong></label>
            <select
              id="collection"
              v-model="collection"
              class="custom-select custom-select-sm"
              @change="filter"
            >
              <option :value="null">All Collections</option>
              <option
                v-for="collection in collections"
                :key="collection"
                :value="collection"
              >
                {{ collection }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="level"><strong>Archival level</strong></label>
            <select
              id="level"
              v-model="levelOfDescription"
              class="custom-select custom-select-sm"
              @change="filter"
            >
              <option :value="null">All Levels</option>
              <option value="Collection,Fonds">Collection</option>
              <option value="File,Series,Sub-file,Sub-fonds,Sub-series">
                Section
              </option>
              <option value="Item,Sub-item">Item</option>
            </select>
          </div>
          <p><strong>Production date</strong></p>
          <div class="form-group">
            <label for="from">After</label>
            <input
              id="from"
              type="text"
              class="form-control form-control-sm"
              v-model="prodDateStart"
              placeholder="11/2000 or 100BC or d/m/y"
              @change="filter"
            />
          </div>
          <div class="form-group">
            <label for="until">Before</label>
            <input
              id="until"
              type="text"
              class="form-control form-control-sm"
              v-model="prodDateEnd"
              placeholder="11/2000 or 100BC or d/m/y"
              @change="filter"
            />
          </div>
          <div class="form-group">
            <label for="language"><strong>Language</strong></label>
            <input
              id="language"
              type="text"
              class="form-control form-control-sm"
              v-model="languageOfMaterial"
              @change="filter"
            />
          </div>
        </form>
      </div>
      <div class="col-md-8 col-lg-9 col-xl-10">
        <!-- Page navigation -->
        <div class="my-3">
          <div
            v-if="resultCount > 0"
            class="d-flex align-items-center flex-wrap"
            style="gap: 1rem"
          >
            <div class="btn-group" role="group" aria-label="Page navigation">
              <button
                id="prev"
                :disabled="pageRecordLow <= 1"
                type="button"
                @click="prev"
                class="btn btn-primary"
              >
                Previous
              </button>
              <button
                id="next"
                :disabled="pageRecordHigh >= resultCount"
                type="button"
                @click="next"
                class="btn btn-primary"
              >
                Next
              </button>
            </div>
            <div
              class="btn-group d-none d-xl-block"
              role="group"
              aria-label="Page layout"
            >
              <button
                id="listview"
                :disabled="!gridView"
                type="button"
                @click="gridView = false"
                class="btn btn-primary"
              >
                <span class="sr-only">View as list</span>
                <BIconListStars />
              </button>
              <button
                id="gridview"
                :disabled="gridView"
                type="button"
                @click="gridView = true"
                class="btn btn-primary"
              >
                <span class="sr-only">View as grid</span>
                <BIconGrid3x3GapFill />
              </button>
            </div>
            <div class="flex-grow-1 text-center">
              {{ pageRecordLow }}-{{ pageRecordHigh }} of
              {{ resultCount }} results
            </div>
            <label class="d-flex align-items-center m-0" style="gap: 0.5rem">
              <span class="flex-shrink-0">Order By</span>
              <select v-model="orderBy" @change="search" class="custom-select">
                <option value="null">Relevance</option>
                <option value="uniid">Object Num / Ref Code &#x2191;</option>
                <option value="uniid,">Object Num / Ref Code &#x2193;</option>
                <option value="dates">Dates &#x2191;</option>
                <option value="dates,">Dates &#x2193;</option>
              </select>
            </label>
          </div>
          <div class="my-3">
            You searched <strong>{{ query }}</strong>
          </div>
          <div v-if="!refreshing && resultCount == 0">
            Your search didn't return any records. Try removing some filters and
            running the search again.
          </div>
        </div>
        <!-- Results -->
        <div class="d-flex flex-wrap" v-if="!refreshing && resultCount > 0">
          <div
            v-for="result in results"
            :key="result.irn"
            class="d-flex flex-grow-1 border-top py-3"
            :style="
              'flex-basis:100%;' + ((gridView && 'flex-basis:28rem;') || '')
            "
          >
            <router-link
              class="thumbnail-container flex-shrink-0 text-center overflow-hidden"
              :to="{
                name: 'Details',
                query: {
                  irn: result.irn,
                  catType: result.catType,
                  referrer: '/results',
                  q: query,
                },
              }"
            >
              <img
                v-if="result.imageUrl != null"
                :src="result.imageUrl"
                :alt="result.imageAltText"
              />
              <div v-else class="no-image">
                <small>No image available</small>
              </div>
            </router-link>
            <div
              v-if="!gridView || true"
              class="d-flex flex-column mx-2"
              style="gap: 0.25rem"
            >
              <router-link
                :to="{
                  name: 'Details',
                  query: {
                    irn: result.irn,
                    catType: result.catType,
                    referrer: '/results',
                    q: query,
                  },
                }"
              >
                {{
                  (result.catType === "N" && result.narPurpose) ||
                  result.summaryData
                }}
              </router-link>
              <div>
                {{
                  (() => {
                    const description =
                      result.description || result.title || "";

                    if (description.length > 300) {
                      return description.substring(0, 300) + "...";
                    }

                    return description;
                  })()
                }}
              </div>
              <div v-if="result.collection">
                <strong>Collection:</strong>
                {{ result.collection }}
              </div>
            </div>
          </div>
        </div>
        <div v-show="refreshing" class="w-100 text-center">
          <div class="spinner-border m-5" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          v-if="resultCount > 0"
          class="my-3 d-flex flex-wrap align-items-center"
          style="gap: 1rem"
        >
          <div class="btn-group" role="group" aria-label="Page navigation">
            <button
              id="prevBottom"
              :disabled="pageRecordLow <= 1"
              type="button"
              @click="prev"
              class="btn btn-primary"
            >
              Previous
            </button>
            <button
              id="nextBottom"
              :disabled="pageRecordHigh >= resultCount"
              type="button"
              @click="next"
              class="btn btn-primary"
            >
              Next
            </button>
          </div>
          <div class="flex-grow-1 text-center">
            Showing {{ pageRecordLow }} to {{ pageRecordHigh }} of
            {{ resultCount }} records.
          </div>
          <label class="d-flex align-items-center m-0" style="gap: 0.5rem">
            <span class="flex-shrink-0">Order By</span>
            <select v-model="orderBy" class="custom-select" @change="search">
              <option value="null">Relevance</option>
              <option value="uniid">Object Num / Ref Code &#x2191;</option>
              <option value="uniid,">Object Num / Ref Code &#x2193;</option>
              <option value="dates">Dates &#x2191;</option>
              <option value="dates,">Dates &#x2193;</option>
            </select>
          </label>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu.vue";
import axios from "axios";
import {
  BIconFunnelFill,
  BIconGrid3x3GapFill,
  BIconListStars,
  BIconQuestionCircle,
} from "bootstrap-icons-vue";
import Mark from "mark.js";
import $cookies from "vue-cookies";
import Header from "../components/Header.vue";

export default {
  name: "Results",
  components: {
    BIconFunnelFill,
    BIconGrid3x3GapFill,
    BIconListStars,
    BIconQuestionCircle,
    Menu,
    Header,
  },
  mixins: [],
  watch: {
    results: function () {
      const query = this.query;

      if (query) {
        setTimeout(() => {
          const marker = new Mark("#app");

          marker.unmark();
          marker.mark(query.replaceAll(/[^\w\s]+/g, ""));
        }, 200);
      }
    },
  },
  data() {
    return {
      query: null,
      groupName: null,
      collection: null,
      levelOfDescription: null,
      languageOfMaterial: null,
      prodDateStart: null,
      prodDateEnd: null,
      prodDate: null,
      images: null,
      collections: null,
      groupNames: null,
      orderBy: null,
      results: null,
      startingRecord: 0,
      stepVal: 10,
      pageRecordHigh: 0,
      pageRecordLow: 0,
      gridView: false,
      resultCount: null,
      refreshing: false,
      offCanvasFormActive: false,
    };
  },
  methods: {
    // Navigates to next page of results.
    next() {
      this.startingRecord = this.startingRecord + this.stepVal;
      this.search();
    },
    // Navigates to previous page of results.
    prev() {
      this.startingRecord = this.startingRecord - this.stepVal;
      this.search();
    },
    // Resets the page counters and does a search when the filter form changes.
    filter(e) {
      e.preventDefault();

      if (this.query) {
        this.resultCount = 0;
        this.startingRecord = 0;
        this.pageRecordHigh = 0;
        this.pageRecordLow = 0;
        if (this.languageOfMaterial === "") {
          this.languageOfMaterial = null;
        }
        if (this.prodDateStart == null) {
          this.prodDateStart = "";
        }
        if (this.prodDateEnd == null) {
          this.prodDateEnd = "";
        }
        this.prodDate = this.prodDateStart + "," + this.prodDateEnd;
        this.offCanvasFormActive = false;
        this.search();
      }
    },
    // Does a regular or advanced search.
    search() {
      this.refreshing = true;

      const payload = {
        advancedText: this.query, // Used only on search/advanced
        autoSuggestion: this.query, // Used only on search/results
        groupName: this.groupName, // Used only on search/results
        startingRecord: this.startingRecord,
        orderBy: this.orderBy,
        collection: this.collection,
        levelOfDescription: this.levelOfDescription,
        languageOfMaterial: this.languageOfMaterial,
        prodDate: this.prodDate, // Todo fix
        images: this.images,
      };

      let url = null;

      if (this.groupName === "All Fields") {
        this.$gtag.event("search_advanced", { search_string: this.query });
        url = process.env.VUE_APP_WS_URL + "/gdc/search/advanced";
      } else {
        this.$gtag.event("search", {
          search_string: this.query,
          search_field: this.groupName,
        });
        url = process.env.VUE_APP_WS_URL + "/gdc/search/results";
      }

      axios
        .get(url, {
          params: payload,
        })
        .then((response) => {
          this.results = response.data;
          // Set saved search cookie
          // Todo check if SameSite: "None" and Secure: true need to be added.
          $cookies.set("gdc_last_search", payload);

          if (this.results.length > 0) {
            this.resultCount = this.results[0].counter;
            if (this.resultCount === 0) {
              // Unique id queries return this so set to 1.
              this.resultCount = 1;
            }
            this.pageRecordLow = this.startingRecord + 1;
            this.pageRecordHigh = this.startingRecord + this.stepVal;
          } else {
            this.resultCount = 0;
          }

          if (this.results.length < this.stepVal) {
            this.pageRecordHigh = this.resultCount;
          }
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
  },
  // Initialises the search form and reruns last query for returning users.
  mounted() {
    if (this.$route.query.q) {
      // Run new search
      this.query = this.$route.query.q;
      this.groupName = this.$route.query.gn;
      this.search();
    } else {
      // Re-run previous search
      if ($cookies.get("gdc_last_search")) {
        const cookie = $cookies.get("gdc_last_search");
        if (cookie.groupName) {
          // Used only on search/results
          this.query = cookie.autoSuggestion;
          this.groupName = cookie.groupName;
        } else {
          this.query = cookie.advancedText; // Used only on search/advanced
        }
        this.startingRecord = cookie.startingRecord;
        this.orderBy = cookie.orderBy;
        this.collection = cookie.collection;
        this.levelOfDescription = cookie.levelOfDescription;
        this.languageOfMaterial = cookie.languageOfMaterial;
        this.prodDate = cookie.prodDate;
        this.images = cookie.images;
        this.search();
      }
    }

    // Get drop down list values from the server.
    axios
      .get(process.env.VUE_APP_WS_URL + "/gdc/search/collectionsLookup", {})
      .then((response) => {
        this.collections = response.data;
      });
  },
};
</script>

<!-- Place scoped component only. -->
<style scoped>
.routerLinkUnderline {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .row-offcanvas {
    position: relative;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    right: 0;
  }

  .row-offcanvas.active {
    right: 100vw;
  }

  .sidebar-offcanvas {
    position: absolute !important;
    right: -100vw;
    top: 0;
  }
}
</style>
