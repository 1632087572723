<template>
  <header>
    <a class="d-none d-sm-inline" href="https://www.gla.ac.uk/" target="_blank">
      <img class="logo" src="@/assets/university-of-glasgow-min.png" alt="University of Glasgow Logo" />
    </a>
    <router-link to="/" class="text-white text-decoration-none mx-2 my-3" style="font-size:1.25rem;">
      Collections
    </router-link>
    <slot />
    <Menu />
  </header>
</template>

<script>
import Menu from './Menu.vue';

export default {
  name: 'Header',
  components: { Menu },
}
</script>

<style scoped>
.logo {
  height: 100%;
  width: 100%;
}

</style>
