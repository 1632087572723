import axios from 'axios'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'

// todo add app/global initialisation here

// Initialise Axios to use env url, retain login credentials.
axios.defaults.baseURL = process.env.VUE_APP_WS_URL
axios.defaults.withCredentials = true
axios.interceptors.response.use((response) => response, (error) => {
  let msg = error
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.error) {
        msg = error.response.data.error
        msg = msg.replace('java.lang.RuntimeException: ', '')
      }
    }
  } else if (error == 'Error: Network Error') {
    msg = 'There was a problem connecting to the server. Please check your network access and try again.'
  }

  if (msg != null) {
    alert(msg)
  }

  throw error
})

const app = createApp(App)

app.use(router)

const gaEnabled = localStorage.getItem('analytics') !== 'false'

app.use(VueGtag, {
  config: { id: 'G-7E7118J8VK' },
  enabled: gaEnabled
}, router)

app.mount('#app')

window.addEventListener('storage', (event) => event.key === 'analytics' && document.location.reload())
