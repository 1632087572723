<template>
  <Header />
  <main class="container pb-5">
    <h2 id="accessibility-statement-for-website-name">
      Accessibility statement
    </h2>

    <p>
      This accessibility statement applies to the
      <a target="_blank" rel="noopener noreferrer" href="https://www.gla.ac.uk/collections">
        Collections Website
      </a>.
    </p>

    <p>
      This website is run by the University of Glasgow. We want as many people
      as possible to be able to use this website. For example, that means you
      should be able to:
    </p>

    <ul>
      <li>
        change colours, contrast levels and fonts
      </li>
      <li>
        zoom in up to 300% without the text spilling off the screen
      </li>
      <li>
        navigate most of the website using just a keyboard
      </li>
      <li>
        navigate most of the website using speech recognition software
      </li>
      <li>
        listen to most of the website using a screen reader (including the most
        recent versions of JAWS, NVDA and VoiceOver)
      </li>
    </ul>

    <p>
      We've also made the website text as simple as possible to understand.
    </p>

    <p>
      <a target="_blank" rel="noopener noreferrer" href="https://mcmw.abilitynet.org.uk">
        AbilityNet
      </a>
      has advice on making your device easier to use if you have a disability.
    </p>

    <h3 id="how-accessible-this-website-is">
      How accessible this website is
    </h3>

    <p>
      We know some parts of this website are not fully accessible:
    </p>

    <ul>
      <li>
        you cannot modify the line height or spacing of text
      </li>
      <li>
        you cannot skip to the main content when using a screen reader
      </li>
    </ul>

    <h3 id="feedback-and-contact-information">
      Feedback and contact information
    </h3>

    <p>
      If you need information on this website in a different format like
      accessible PDF, large print, easy read, audio recording or braille:
    </p>

    <ul>
      <li>
        Web:
        <a target="_blank" rel="noopener noreferrer" href="https://www.gla.ac.uk/myglasgow/it/helpdesk">
          UofG Helpdesk
        </a>
      </li>
      <li>
        E-mail:
        <!-- <a href="mailto:ithelpdesk@glasgow.ac.uk"> -->
        ithelpdesk@glasgow.ac.uk
        <!-- </a> -->
      </li>
      <li>
        Call:
        <!-- <a href="tel:+44 141 330 4800"> -->
        0141 330 4800
        <!-- </a> -->
      </li>
    </ul>

    <p>
      We'll consider your request and get back to you in 10 days.
    </p>

    <h4>British Sign Language</h4>

    <p>
      BSL users can contact us via contactSCOTLAND-BSL, the online British Sign
      Language interpreting video relay service.
    </p>

    <p>
      Find out more about BSL assistance:
      <a target="_blank" rel="noopener noreferrer" href="https://contactscotland-bsl.org">
        contactSCOTLAND
      </a>
    </p>

    <h3 id="reporting-accessibility-problems-with-this-website">
      Reporting accessibility problems with this website
    </h3>

    <p>
      We're always looking to improve the accessibility of this website. If you
      find any problems not listed on this page or think we're not meeting
      accessibility requirements contact:
    </p>

    <ul>
      <li>
        Web:
        <a target="_blank" rel="noopener noreferrer" href="https://www.gla.ac.uk/myglasgow/it/helpdesk">
          UofG Helpdesk
        </a>
      </li>
      <li>
        E-mail:
        <!-- <a href="mailto:ithelpdesk@glasgow.ac.uk"> -->
        ithelpdesk@glasgow.ac.uk
        <!-- </a> -->
      </li>
      <li>
        Call:
        <!-- <a href="tel:+44 141 330 4800"> -->
        0141 330 4800
        <!-- </a> -->
      </li>
    </ul>

    <h3 id="enforcement-procedure">
      Enforcement procedure
    </h3>

    <p>
      The Equality and Human Rights Commission (EHRC) is responsible for
      enforcing the Public Sector Bodies (Websites and Mobile Applications) (No.
      2) Accessibility Regulations 2018 (the 'accessibility regulations'). If
      you're not happy with how we respond to your complaint,
      <a target="_blank" rel="noopener noreferrer" href="https://www.equalityadvisoryservice.com/">
        contact the Equality Advisory and Support Service (EASS)
      </a>.
    </p>

    <h2 id="technical-information-about-this-websites-accessibility">
      Technical information about this website's accessibility
    </h2>

    <p>
      The University of Glasgow is committed to making its website accessible,
      in accordance with the Public Sector Bodies (Websites and Mobile
      Applications) (No. 2) Accessibility Regulations 2018.
    </p>

    <h3 id="compliance-status">
      Compliance status
    </h3>

    <p>
      This website is partially compliant with the
      <a target="_blank" rel="noopener noreferrer" href="https://www.w3.org/TR/WCAG21/">
        Web Content Accessibility Guidelines version 2.1
      </a>
      AA standard, due to the non-compliances listed below.
    </p>

    <h2 id="non-accessible-content">
      Non-accessible content
    </h2>

    <p>
      The content listed below is non-accessible for the following reasons.
    </p>

    <h3 id="non-compliance-with-the-accessibility-regulations">
      Non-compliance with the accessibility regulations
    </h3>

    <p>
      Currently, there is no built-in way to modify the line height or spacing
      of text. You can rely on third party extensions for your browser of
      choice.
    </p>

    <p>
      Currently there is no way to skip to the main content when using a screen
      reader.
    </p>

    <h2 id="preparation-of-this-accessibility-statement">
      Preparation of this accessibility statement
    </h2>

    <p>
      This statement was prepared on the 1st of November 2022. It was last
      reviewed on 1st of November 2022.
    </p>

    <p>
      This website was last tested on the 1st of November 2022. The test was
      carried out internally by the University of Glasgow.
    </p>

    <p>
      We tested all published pages.
    </p>
  </main>
</template>

<script>
import Header from '../components/Header.vue';

export default {
  name: "Accessibility",
  components: { Header }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  line-height: 1.5rem;
}

main h2 {
  margin: 3rem 0 0.5rem 0;
}

main h3 {
  margin: 2rem 0 0.5rem 0;
}

main h4 {
  margin: 1rem 0 0.5rem 0;
}

main p,
main ul {
  margin: 1rem 0 0 0;
}

main a {
  font-weight: bold;
}
</style>
