<!--
Displays contact info.
@author Brian McKenna
-->
<template>
  <Header />
  <main class="container pb-5">
    <h2>
      Contact
    </h2>
    <div class="row">
      <div class="col-sm-6">
        <img src="@/assets/contact-1.jpg" class="w-100 rounded py-3" alt="resources" />
        <p>
          The University of Glasgow Collections site brings together
          descriptions from the Library's
          <a href="https://www.gla.ac.uk/myglasgow/archivespecialcollections" target="_blank" class="font-weight-bold">
            Archives & Special Collections
          </a>
          and
          <a href="https://www.gla.ac.uk/hunterian" target="_blank" class="font-weight-bold">
            The Hunterian
          </a>
          into a searchable portal for inspiration and exploration and for
          supporting research and teaching.
        </p>
        <p>
          The collections are held in multiple physical locations across our
          campuses. More images and multimedia materials are being added all the
          time so we hope you will keep returning to discover new things.
        </p>
      </div>
      <div class="col-sm-6">
        <img src="@/assets/contact-2.jpg" class="w-100 rounded py-3" alt="locations" />
        <p>
          At the heart of the University of Glasgow since 1807, The Hunterian
          connects people with stories, individuals and ideas found in stunning
          collections of objects, belongings and artworks.
        </p>
        <p>
          Today The Hunterian
          is a space for questioning and experimentation, for enjoyment and
          enrichment, for connection and collaboration and for reflecting on the
          role museums and individuals can play in furthering equity, justice
          and diversity in our society. More images and multimedia materials are
          being added all the time so we hope you will keep returning to
          discover new things.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <h3 class="my-2">Contact Archives & Special Collections</h3>
        <div>
          <a href="https://www.gla.ac.uk/myglasgow/archivespecialcollections" target="_blank" class="btn">
            <BIconGlobe />
            <span class="align-middle">
              Website
            </span>
          </a>
        </div>
        <div>
          <a href="https://www.gla.ac.uk/myglasgow/archivespecialcollections/enquiries" target="_blank" class="btn">
            <BIconQuestionCircleFill />
            <span class="align-middle">
              Make an enquiry
            </span>
          </a>
        </div>
        <div>
          <a href="https://www.gla.ac.uk/myglasgow/archivespecialcollections/consultingourcollections" target="_blank"
            class="btn">
            <BIconCalendarCheckFill />
            <span class="align-middle">
              Book a visit
            </span>
          </a>
        </div>
        <div>
          <a href="mailto:library-asc@glasgow.ac.uk" class="btn">
            <BIconEnvelopeFill />
            <span class="align-middle">
              library-asc@glasgow.ac.uk
            </span>
          </a>
        </div>
        <div>
          <a href="https://www.gla.ac.uk/myglasgow/archivespecialcollections/uofglasgowasconsocialmedia" target="_blank"
            class="btn">
            <BIconPeopleFill />
            <span class="align-middle">
              Social media
            </span>
          </a>
        </div>
        <div>
          <a href="tel:+441413306767" class="btn">
            <BIconTelephoneFill />
            <span class="align-middle">
              +44&nbsp;141&nbsp;330&nbsp;6767
            </span>
          </a>
          <em>
            (phone and voicemail)
          </em>
          <div class="my-2 text-right">
            <small>
              Our enquiry line is staffed Monday to Friday 9.30 to 4.45
              <br />
              except on University
              <a href="https://www.gla.ac.uk/myglasgow/humanresources/all/worklife/publicholidays/public"
                target="_blank" class="font-weight-bold text-nowrap">
                public holidays
              </a>
            </small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <h3 class="my-2">
          Contact The Hunterian
        </h3>
        <div>
          <a href="https://www.gla.ac.uk/hunterian" target="_blank" class="btn">
            <BIconGlobe />
            <span class="align-middle">
              Website
            </span>
          </a>
        </div>
        <div>
          <a href="https://www.gla.ac.uk/hunterian/visit/ourvenues" target="_blank" class="btn">
            <BIconGeoAltFill />
            <span class="align-middle">
              Visit us
            </span>
          </a>
        </div>
        <div>
          <a href="https://www.gla.ac.uk/hunterian/about/thehunterianatkelvinhall/thehunteriancollectionsstudycentre/#d.en.400292,publicaccesstokelvinhall,howtoaccessthehunteriancollections"
            target="_blank" class="btn">
            <BIconCalendarCheckFill />
            <span class="align-middle">
              Book a research visit
            </span>
          </a>
        </div>
        <div>
          <a href="mailto:hunterian-collections@glasgow.ac.uk" class="btn">
            <BIconEnvelopeFill />
            <span class="align-middle">
              hunterian-collections@glasgow.ac.uk
            </span>
          </a>
        </div>
        <div>
          <a href="tel:+441413304221" class="btn">
            <BIconTelephoneFill />
            <span class="align-middle">
              +44&nbsp;141&nbsp;330&nbsp;4221
            </span>
          </a>
        </div>
        <div>
          <a href="https://twitter.com/hunterian" class="btn">
            <BIconTwitter />
          </a>
          <a href="https://www.facebook.com/HunterianGlasgow" class="btn">
            <BIconFacebook />
          </a>
          <a href="https://www.instagram.com/hunterianglasgow" class="btn">
            <BIconInstagram />
          </a>
        </div>
      </div>
    </div>
    <div class="text-center my-4">
      <h4>
        Post
      </h4>
      <p>
        Please address any postal enquiry to:
      </p>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div>
          <address class="bg-light my-3 p-4">
            <em>
              Archives & Special Collections<br />
              University of Glasgow, Library<br />
              Hillhead Street, Glasgow, G12 8QE
            </em>
          </address>
        </div>
      </div>
      <div class="col-sm-6">
        <div>
          <address class="bg-light my-3 p-4">
            <em>
              The Hunterian<br />
              University of Glasgow<br />
              Glasgow, G12 8QQ
            </em>
          </address>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { BIconCalendarCheckFill, BIconEnvelopeFill, BIconFacebook, BIconGeoAltFill, BIconGlobe, BIconInstagram, BIconPeopleFill, BIconQuestionCircleFill, BIconTelephoneFill, BIconTwitter } from 'bootstrap-icons-vue';
import Header from '../components/Header.vue';

export default {
  name: 'Contact',
  components: {
    BIconTelephoneFill,
    BIconEnvelopeFill,
    BIconGlobe,
    BIconQuestionCircleFill,
    BIconPeopleFill,
    Header,
    BIconTwitter,
    BIconFacebook,
    BIconInstagram,
    BIconCalendarCheckFill,
    BIconGeoAltFill
  }
}
</script>

<style scoped>
main h2 {
  margin: 3rem 0 0.5rem 0;
}
</style>
