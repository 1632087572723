import { createRouter, createWebHashHistory } from 'vue-router'
import Accessibility from '../views/Accessibility.vue'
import Contact from '../views/Contact.vue'
import Details from '../views/Details.vue'
import History from '../views/History.vue'
import LandingPage from '../views/LandingPage.vue'
import Narratives from '../views/Narratives.vue'
import Privacy from '../views/Privacy.vue'
import Results from '../views/Results.vue'
import Help from '../views/Help.vue'

const routes = [{
  path: '/',
  name: 'LandingPage',
  component: LandingPage
}, {
  path: '/results',
  name: 'Results',
  component: Results
}, {
  path: '/details',
  name: 'Details',
  component: Details
}, {
  path: '/help',
  name: 'Help',
  component: Help
}, {
  path: '/contact',
  name: 'Contact',
  component: Contact
}, {
  path: '/narratives',
  name: 'Narratives',
  component: Narratives
}, {
  path: '/history',
  name: 'History',
  component: History
}, {
  path: '/accessibility',
  name: 'Accessibility',
  component: Accessibility
}, {
  path: '/privacy',
  name: 'Privacy',
  component: Privacy
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
