<!--
LandingPage shows subgroups and links to this page which shows all images for a particular subgroup
-->
<template>
  <Header />
  <main class="album bg-light py-3 pb-5 mx-3">
    <section>
      <router-link to="/" class="lead p-3">
        <BIconChevronLeft style="position:relative;top:-0.1rem;" />
        Home
      </router-link>
      <h1 class="text-center p-3">{{ narPurpose }}s</h1>
      <div class="container d-flex flex-wrap justify-content-center" style="gap:1rem;">
        <div v-if="loading" class="flex-grow-1 text-center">
          <div class="spinner-border m-5" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-else v-for="item in featured" :key="item.imageUrl" class="card flex-grow-1"
          style="flex-basis:24rem;max-width:34rem;">
          <div class="card-body d-flex flex-column" style="gap:1rem;">
            <div class="card-text d-flex flex-wrap justify-content-center align-items-start" style="gap:1rem;">
              <img class="flex-shrink-0 bg-light" v-if="item.imageUrl" :src="item.imageUrl" :alt="item.imageAltText" />
              <div class="flex-grow-1" style="flex-basis:70%;" v-html="item.text" />
            </div>
            <router-link class="routerLink mt-auto" :to="{
              name: 'Details', query: {
                irn: item.irn, catType: 'N', referrer: '/narratives', narPurpose: narPurpose
              }
            }">
              {{ item.title }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { BIconChevronLeft } from 'bootstrap-icons-vue'
import 'jquery'
import Header from '../components/Header.vue'

export default {
  name: 'Narratives',
  components: { Header, BIconChevronLeft },
  mixins: [],
  data() {
    return {
      featured: null,
      narPurpose: null,
      loading: false
    }
  },
  methods: {
    loadData() {
      const narPurpose = this.$route.query.narPurpose
      this.narPurpose = narPurpose
    },
    // Resets the view's data.
    unloadData() {
      this.narPurpose = null
    }
  },
  mounted() {
    // Load data
    this.loadData()
    this.loading = true

    // Get featured content.
    axios.get(process.env.VUE_APP_WS_URL + '/gdc/search/featuredGroup',
      {
        params:
        {
          narPurpose: this.narPurpose
        }
      })
      .then(response => {
        this.featured = response.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  unmounted() {
    this.unloadData()
  }
}
</script>

<!-- Place scoped component only. -->
<style scoped>
.routerLink {
  text-decoration: underline;
}

main img {
  width: 90px;
  height: 90px;
  object-fit: contain;
}
</style>
