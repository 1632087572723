<!--
Landing screen with the initial search box (linking to the results screen) and featured
content (linking to the details screen).
@author Mark Herraghty, Brian McKenna
-->
<template>
  <Header />
  <main>
    <section class="jumbotron text-center rounded-0 mx-sm-3">
      <div class="container">
        <h2 class="jumbotron-heading">Explore the Collection</h2>
        <p class="lead mx-md-5">
          Search the collections of The Hunterian, Archives & Special
          Collections
        </p>
        <form class="d-flex flex-column mx-md-5" @submit.prevent="handleSubmit">
          <div class="input-group flex-wrap">
            <input
              type="text"
              class="form-control typeahead"
              placeholder="Search Title, Description, Person, Place, Period, Object Number or Reference Code"
              aria-label="Search Title, Description, Person, Place, Period, Object Number or Reference Code"
              v-model="filter"
            />
            <div class="input-group-append">
              <input type="submit" hidden />
              <span class="sr-only">Search</span>
            </div>
          </div>
          <router-link
            class="btn btn-link px-0 align-self-end"
            :to="{ name: 'Help' }"
          >
            Need help?
          </router-link>
        </form>
      </div>
    </section>
    <div v-show="loading" class="text-center">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      class="bg-light"
      v-for="(group, narPurpose) in featuredByNarPurpose"
      :key="narPurpose"
    >
      <section class="container album">
        <h2 class="text-center py-5">{{ narPurpose }}s</h2>
        <div
          class="d-flex flex-wrap justify-content-center my-3"
          style="column-gap: 5rem; row-gap: 1rem"
        >
          <div v-for="item in group" :key="item.imageUrl">
            <div class="text-center">
              <router-link
                class="text-underline"
                :to="{
                  name: 'Details',
                  query: { irn: item.irn, catType: 'N', referrer: '/' },
                }"
              >
                <img
                  v-if="item.imageUrl"
                  :src="item.imageUrl"
                  :alt="item.imageAltText || 'collection picture'"
                />
                <div class="p-2">
                  {{ item.title }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="text-center py-5">
          <router-link
            class="btn btn-primary text-white px-4"
            :to="{
              name: 'Narratives',
              query: { narPurpose: narPurpose, catType: 'N', referrer: '/' },
            }"
          >
            Explore All {{ narPurpose }}s
          </router-link>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Bloodhound from "corejs-typeahead/dist/bloodhound";
import "corejs-typeahead/dist/typeahead.jquery";
import $ from "jquery";
import Header from "../components/Header.vue";

export default {
  name: "Search",
  components: { Header },
  mixins: [],
  data() {
    return {
      filter: null,
      featured: null,
      loading: true,
    };
  },
  methods: {
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    // Pass the selected suggestion to Results for processing.
    selectSuggestion(ev, suggestion) {
      this.$router.push({
        path: "results",
        query: { q: suggestion[0], gn: suggestion[2] },
      });
    },
    // Select the first/default typeahead suggestion when the user hits enter in the search form.
    handleSubmit() {
      $(".tt-suggestion:first-child").trigger("click");
    },
  },
  computed: {
    featuredByNarPurpose() {
      if (!this.featured) {
        return this.featured;
      }
      return this.groupBy(this.featured, "narPurpose");
    },
  },
  mounted() {
    // Set up typeahead.
    const suggestions = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: process.env.VUE_APP_WS_URL + "/gdc/search/autosuggestions?q=%q",
        wildcard: "%q",
      },
      identify: (obj) => {
        return obj[0];
      },
    });

    $(".typeahead")
      .typeahead(
        {
          hint: true,
          highlight: true /* Enable substring highlighting */,
          minLength: 1 /* Specify minimum characters required for showing suggestions */,
        },
        {
          name: "search",
          limit: 10 /* dropdown height change the getAutoSuggestions SQL query rownum to match */,
          source: suggestions,
          display: function (data) {
            return data[0];
          },
          templates: {
            empty: function () {
              return "<p>No suggestion</p>";
            },
            suggestion: function (data) {
              let display = data[0];

              display = display + " - " + data[2];

              return "<p>" + display + "</p>";
            },
          },
        }
      )
      .bind("typeahead:select", this.selectSuggestion)
      .bind("typeahead:autocomplete", this.selectSuggestion);

    // Get featured content.
    axios
      .get(process.env.VUE_APP_WS_URL + "/gdc/search/featured")
      .then((response) => {
        this.featured = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<!-- Place scoped component only. Note that this did not work for typeahead styles,
which are declared globally in App.vue. -->
<style scoped>
main img {
  width: 250px;
  height: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}
</style>
