<template>
  <Header />
  <main class="container pb-5">
    <section>
      <h2>
        Privacy
      </h2>
      <p>
        This statement outlines the information gathered during your visit to
        the University of Glasgow Collections web pages and explains how this
        information is treated. If you choose to request information from this
        site you will need to pass personal data to the University via this
        site. Where you actively provide personal data to the University [for
        example, by sending an email via the contact page] this data will be
        used only for the service you have requested unless you consent
        otherwise at the time of submitting the data.
      </p>
      <h3>
        Data protection
      </h3>
      <p>
        The gathering, storage, use, release and disposal of personal
        information is regulated by the General Data Protection Regulation. The
        University's policy and guidelines with respect to the regulations, are
        published by the Data Protection and Freedom of Information Office.
      </p>
      <h3>
        Freedom of Information (Scotland) Act
      </h3>
      <p>
        The records of the University of Glasgow are subject to the terms of the
        Freedom of Information (Scotland) 2002 Act. The Act gives individuals a
        general right of access to all types of recorded information, of any
        age, held by the University. The University's policy and guidelines with
        respect to the Freedom of Information Act (Scotland) 2002 are published
        by the Data Protection and Freedom of Information Office.
      </p>
    </section>
    <section>
      <h2>
        The information collected
      </h2>
      <h3>
        Log files
      </h3>
      <p>
        The University Glasgow Collections site does not automatically capture
        or store personal data from visitors to the site, other than to log your
        IP address and session information. Session information includes the
        time and duration of your visit to the site, the search terms used, and
        the internet browser used. This information will only be accessed by
        authorised persons of the University of Glasgow or its agents. The
        information will be retained by the University and will only be used for
        the purpose of (a) managing the system, (b) the identification of broken
        links, and (c) for statistical and audit purposes.
      </p>
      <h3>
        Contact Emails
      </h3>
      <p>
        You may need to provide personal information in order to get in touch
        via email links. The processing and retention of this information will
        be explained at the point of collection.
      </p>
      <p>
        This privacy policy does not cover the email links within this site or
        links to other non-University external internet sites.
      </p>
      <h3>
        Cookies
      </h3>
      <p>
        Cookies are small text files placed on your device by the web server via
        your internet browser, and may be used to collect information to help
        you and prepare customised search pages for you. Cookies may remain on
        your computer either for the duration of the session or indefinitely.
        You can configure most standard web browsers to refuse cookies, but this
        will impact your ability to use the search services of this system.
      </p>
      <p>
        Occasionally, we give users the opportunity to save settings for viewing
        pages. This involves the setting of a cookie. We do not track such
        cookies.
      </p>
      <p>
        Information on cookies can be obtained from
        <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.
      </p>

      <div class="my-5">
        <div class="custom-control custom-switch my-3">
          <input type="checkbox" class="custom-control-input" id="settings" checked disabled />
          <label class="custom-control-label" for="settings">
            Settings and navigation Cookies (Required)
          </label>
        </div>
        <div class="custom-control custom-switch my-3">
          <input type="checkbox" class="custom-control-input" id="analytics" :checked="checked" @input="toggle" />
          <label class="custom-control-label" for="analytics">Google analytics</label>
        </div>
      </div>

      <h4>
        Google Analytics
      </h4>
      <p>
        The University and our authorised agents make use of Google Analytics to
        produce anonymised statistics on website usage: to help us to improve
        our site and the experience of our users. Google Analytics uses cookies
        in this process. The information generated by the cookie about your use
        of the website will be transmitted to and stored by Google on servers in
        the United States. Google will use this information for the purpose of
        evaluating your use of the website, compiling reports on website
        activity for website operators and providing other services relating to
        website activity and internet usage. Google may also transfer this
        information to third parties where required to do so by law, or where
        such third parties process the information on Google's behalf. All data
        collected is anonymised.
      </p>
    </section>
  </main>
</template>

<script>
import Header from '../components/Header.vue';

export default {
  name: "Privacy",
  computed: {
    checked: () => localStorage.getItem("analytics") !== "false"
  },
  methods: {
    toggle: (e) => {
      localStorage.setItem("analytics", e.target.checked);
      document.location.reload();
    }
  },
  components: { Header }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main h2 {
  margin: 3rem 0 0.5rem 0;
}

main h3 {
  margin: 2rem 0 0.5rem 0;
}

main p {
  margin: 1rem 0 0 0;
}

main a {
  font-weight: bold;
}
</style>
