<template>
  <div v-if="privacy" class="text-white p-5" style="background-color:#000;">
    <span class="consent-switch" id="consent-intro">
      The University of Glasgow uses cookies for troubleshooting and analytics.
      Find out more about our
      <router-link to="/privacy" class="text-info">
        Privacy policy and settings
      </router-link>.
    </span>
    <button @click="accept" class="btn btn-primary mx-3" aria-label="close privacy bar">
      Accept and close
    </button>
  </div>
  <router-view />
</template>

<script>
import router from './router';

export default {
  name: 'App',
  computed: {
    privacy: () => !localStorage.getItem('analytics')
  },
  methods: {
    accept: () => {
      localStorage.setItem('analytics', true)
      document.location.reload()
    }
  },
  components: { router }
}
</script>

<!-- Place non-scoped global styles here. -->
<style>
:root {
  --primary: #003865;
}

body {
  font-family: 'PT Sans', sans-serif;
  text-rendering: optimizeLegibility;
}

header {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #003865;
}

a {
  color: #003865 !important;
}

.thumbnail-container {
  background-color: #f7f7f7;
  width: 90px;
  height: 90px;
  text-align: center;
}

.thumbnail-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.no-image {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.twitter-typeahead {
  flex-grow: 1;
}

.tt-menu {
  width: 100%;
  border: 1px solid #ccc;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  background-color: #fff;
}

.tt-cursor {
  cursor: pointer;
}

.tt-suggestion {
  margin: 0;
  padding: 0.5rem;
  text-align: left;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

.tt-hint {
  color: #ccc;
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
</style>
