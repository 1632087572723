<!--
Displays a wait/loading spinner.
Usage:
  HTML:
    <Spinner ref="spinner" {text="Optional additional text after loading message."} />
  JavaScript:
    this.$refs.spinner.spin()
    this.$refs.spinner.stop()
@author bmk11u
-->
<template>
  <div v-if="show" class="d-flex align-items-center">
    <strong>Loading... {{ text }}</strong>
    <div class="spinner-border m-3 flex-shrink-0" role="status" aria-hidden="true"></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    text: String
  },
  methods: {
    spin() {
      this.show = true
    },
    stop() {
      this.show = false
    }
  },
  data() {
    return {
      show: false
    }
  }
}
</script>

