<template>
  <div class="dropdown m-2 ml-auto">
    <button
      class="btn text-white"
      type="button"
      id="menu"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Menu
      <BIconCaretDownFill />
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="menu">
      <router-link class="dropdown-item" to="/"> Home </router-link>
      <router-link class="dropdown-item" :to="{ name: 'Help' }">
        Help
      </router-link>
      <router-link class="dropdown-item d-none" :to="{ name: 'History' }">
        Project History
      </router-link>
      <router-link class="dropdown-item" :to="{ name: 'Contact' }">
        Contact
      </router-link>
      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item"
        target="_blank"
        href="https://www.gla.ac.uk/legal/termsofuse"
      >
        Legal
      </a>
      <router-link class="dropdown-item" :to="{ name: 'Accessibility' }">
        Accessibility
      </router-link>
      <router-link class="dropdown-item" :to="{ name: 'Privacy' }">
        Privacy
      </router-link>
      <a
        class="dropdown-item"
        target="_blank"
        href="https://www.gla.ac.uk/foi/"
      >
        Freedom of information
      </a>
      <a
        class="dropdown-item"
        target="_blank"
        href="https://www.gla.ac.uk/legal/freedomofinformation/foipublicationscheme"
      >
        FOI publication scheme
      </a>
      <a
        class="dropdown-item"
        target="_blank"
        href="https://www.gla.ac.uk/services/procurementoffice/modernslaveryact2015statement"
      >
        Modern Slavery Statement
      </a>
      <a
        class="dropdown-item"
        target="_blank"
        href="https://www.gla.ac.uk/legal/copyright"
      >
        &copy; UofG
      </a>
    </div>
  </div>
</template>

<script>
import { BIconCaretDownFill } from "bootstrap-icons-vue";

export default {
  name: "Menu",
  components: { BIconCaretDownFill },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
