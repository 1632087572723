<!--
Displays a help page. @author Brian McKenna
-->
<template>
  <Header />
  <main class="m-5">
    <h1>Collections Guide</h1>

    <div>
      <h2>Search the Collections</h2>

      <p>
        Use the search box on the Collections homepage to start searching across
        the collections of The Hunterian, Archives &amp; Special Collections.
      </p>

      <div tabindex="0" class="collapsible">
        <h3>Main search</h3>
        <div>
          <p>
            The main search box is your starting point for exploring the
            Collections catalogue.
          </p>
          <p class="boxed text-center my-3">
            <img src="@/assets/tips/image001.png" alt="main search box" />
            1. University of Glasgow Collections main search box
          </p>
          <ul>
            <li>
              Start by typing search term(s) into the search box - the search
              will check against the
              <code class="d-block m-2">
                Title, Description, Person, Place, Period, Object Number or
                Reference Code
              </code>
              catalogue fields.
            </li>
            <li>
              As you type, suggested searches will appear to help you locate the
              catalogue records that you are interested in.
            </li>
            <li>
              Click on one of the suggestions or press enter to run a search.
            </li>
            <li>
              The main search box allows for both a
              <i>Suggested Search</i> and an <i>Advanced Search</i>.
            </li>
          </ul>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Suggested Search</h3>
        <div>
          <p>
            Suggested Search helps you explore catalogue records of interest, by
            providing a list of suggestions relevant to your search term.
          </p>
          <ul>
            <li>
              Start by typing <b>one search term</b> into the search box.
              <div class="boxed text-center">
                Suggested Search only works for the first search term that you
                type. Typing more than one words will activate an Advanced
                Search.
              </div>
            </li>
            <li>
              A list of matching terms found in catalogue records will appear
              below the search box. The suggestions are grouped by search field
              (e.g. Title, Description, Person), and include both exact matches
              of the search term and matches that begin with the search term.
            </li>
            <li>
              If you're not sure which field to search in, or would like to
              explore everything containing the search term, click on the
              <i>All fields</i> suggestion.
            </li>
          </ul>
          <div class="boxed">
            <div>
              <b>Example</b>
            </div>
            <p>Type the search term "fly" into the search box.</p>
            <p>
              Suggested Search provides a list of search fields where the exact
              term appears in catalogue records, e.g. "fly" in Description,
              Title or Taxonomy; and matches beginning with the search term,
              e.g. "flying" in Description, "flycatcher" in Taxonomy, or "Flynn"
              in Person.
            </p>
          </div>
          <div class="boxed text-center">
            <img src="@/assets/tips/image002.png" alt="search box with term" />
            2. Suggested search has provided a list of search fields where
            matches of the search term exist in catalogue records.
          </div>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Advanced Search</h3>
        <div>
          <p>
            Advanced Search allows you to search for occurrences in catalogue
            records of all the search terms that you type. It is ideal when
            using more than one search terms, especially combined with search
            operators to narrow your search.
          </p>
          <ul>
            <li>
              Start by typing at least one search term into the search box.
            </li>
            <li>
              You can optionally use <i>Search operators</i> to narrow the
              search.
            </li>
            <li>Press Enter to run the search.</li>
          </ul>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Search operators</h3>
        <div>
          <p>
            Search operators offer advanced search features to narrow down your
            searches. Listed below are the search operators supported by the
            Collections search box - use the information in the table to help
            you understand the use of each operator. The Indicative Results
            column provides a comparison of number of results returned for each
            example.
          </p>

          <div class="scrollable">
            <table>
              <thead>
                <tr>
                  <th>Operator</th>
                  <th>Name</th>
                  <th class="longer">Function</th>
                  <th class="longer">How to use</th>
                  <th class="longer">Examples</th>
                  <th class="longer">Indicative Results</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>+</code></td>
                  <td><p>plus sign</p></td>
                  <td>
                    <p>
                      Tell the search that a term <b>must</b> be included in the
                      results.
                    </p>
                    <p>
                      This is the default setting for advanced searches where no
                      search operators are used.
                    </p>
                  </td>
                  <td>
                    <p>
                      Add a + immediately before the search term (no spaces)
                    </p>
                  </td>
                  <td>
                    <p>
                      Searching for:<br />
                      <code>+Scottish +theatre</code>
                    </p>
                    <p>
                      will return all records in the catalogue that include both
                      the terms Scottish and theatre in one or more fields.
                    </p>
                    <p>
                      <b>Note:</b>searching instead for
                      <code>Scottish theatre</code> without the
                      <code>+</code> operators, will return exactly the same
                      results as with the <code>+</code> operators.
                    </p>
                  </td>
                  <td>
                    <p>
                      Search: <code>+Scottish +theatre</code><br />
                      Number of results: 78725
                    </p>
                    <p>
                      Search: <code>Scottish theatre</code><br />
                      Number of results: 78725
                    </p>
                  </td>
                </tr>
                <tr>
                  <td><code>-</code></td>
                  <td><p>minus sign (hyphen)</p></td>
                  <td>
                    <p>
                      Tell the search that a term <b>must not</b> be included in
                      the results.
                    </p>
                  </td>
                  <td>
                    <p>
                      Add a <code>-</code> immediately before the search term
                      (no spaces)
                    </p>
                  </td>
                  <td>
                    <p>
                      Searching for:<br />
                      <code>Scottish -theatre</code>
                    </p>
                    <p>
                      will return all records in the catalogue that include the
                      term <code>Scottish</code> but exclude the term
                      <code>theatre</code> in one or more fields.
                    </p>
                    <p>
                      Searching for:<br />
                      <code>-Scottish +theatre</code>
                    </p>
                    <p>
                      will return all records in the catalogue that include the
                      term <code>theatre</code> but exclude the term
                      <code>Scottish</code> in one or more fields.
                    </p>
                  </td>
                  <td>
                    <p>
                      Search: <code>Scottish -theatre</code><br />
                      Number of results: 52960
                    </p>
                    <p>
                      Search: <code>-Scottish theatre</code><br />
                      Number of results: 992
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>"&nbsp;"</td>
                  <td><p>double quotes</p></td>
                  <td><p>Search for an exact phrase.</p></td>
                  <td><p>Enclose the phrase in double quotes</p></td>
                  <td>
                    <p>
                      Searching for:<br />
                      <code>"Scottish theatres" </code>
                    </p>
                    <p>
                      will return all records in the catalogue that include the
                      exact phrase <code>Scottish theatres</code>. It will
                      exclude records that include the phrase
                      <code>Scottish theatre</code>.
                    </p>
                  </td>
                  <td>
                    <p>
                      Search: <code>"Scottish theatres"</code> <br />
                      Number of results: 36
                    </p>
                    <p>
                      Search: <code>"Scottish theatre"</code> <br />
                      Number of results: 78674
                    </p>
                  </td>
                </tr>
                <tr>
                  <td><code>*</code></td>
                  <td><p>asterisk</p></td>
                  <td>
                    <p>
                      Search for terms that start with the same letters, or for
                      variations of a search term.
                    </p>
                  </td>
                  <td>
                    <p>
                      Append a <code>*</code> to the word to be affected. Words
                      match if they begin with the word preceding the
                      <code>*</code> operator.
                    </p>
                  </td>
                  <td>
                    <p>
                      Searching for:<br />
                      <code>bon*</code>
                    </p>
                    <p>
                      will return records in the catalogue that include the term
                      <code>bon</code> and terms that begin with
                      <code>bon</code>, e.g. bons, bone, bones, bond, bonds,
                      bonded, Boni
                    </p>
                  </td>
                  <td>
                    <p>
                      Search: <code>bon*</code><br />
                      Number of results: 7276
                    </p>
                    <p>
                      Search: <code>bone</code><br />
                      Number of results: 2453
                    </p>
                    <p>
                      Search: <code>bones</code><br />
                      Number of results: 861
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <h2>Search Results</h2>

      <p>
        Once you submit your search, either by selecting a suggestion or hitting
        Enter, you will be presented with the search results page.
      </p>

      <div tabindex="0" class="collapsible">
        <h3>Search results page</h3>
        <div>
          <p>The Search results page allows you to:</p>

          <ul>
            <li>
              Order results by Relevance, Object Number / Reference Code, or
              Dates.
            </li>
            <li>
              View the number of catalogue records matching your search term(s).
            </li>
            <li>Toggle between List and Grid views.</li>
            <li>View summary information for each search result.</li>
            <li>Access individual records.</li>
            <li>Filter search results.</li>
          </ul>

          <div class="boxed text-center">
            <img
              src="@/assets/tips/image003.png"
              alt='Search results page for the term \"bone"'
            />
            3. Search results page for the term &quot;bone&quot;
          </div>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Order search results</h3>
        <div>
          <p>Use the "Order by" dropdown list to sort search results by:</p>
          <ul>
            <li>
              Relevance (default) most relevant records will appear at the top
              of the search results list. Relevance is based on a number of
              factors, such as how often your search term(s) appear in the
              record; which fields they appear in; and how many other documents
              contain the search terms.
            </li>
            <li>
              Object Number or Reference Code sort results by their unique
              Object Numbers (Hunterian records) and Reference Codes (Archives
              &amp; Special Collections records) in ascending or descending
              order.
            </li>
            <li>
              Dates sort results by Dates of Creation or Acquisition in
              ascending or descending order.
            </li>
          </ul>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Toggle views and navigate results</h3>

        <div>
          <p>
            You can toggle between a list or grid view by clicking on the
            respective buttons at the top left of the search results page. By
            default, results are displayed in list view.
          </p>
          <div class="boxed">
            Grid view might not be available on certain mobile devices. In such
            case, search results can only be viewed as a list.
          </div>
          <p>
            The search results page displays ten records at a time use the Next
            and Previous buttons to navigate to the next/previous set of
            results. The total number of results and your current location are
            displayed at the top of the search results page.
          </p>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>View summary information and access a record</h3>
        <div>
          <p>
            The following information is displayed for each item in the search
            results list:
          </p>
          <ul>
            <li>
              The Summary field, which provides a brief account of each record.
            </li>
            <li>
              A thumbnail of an image associated with a record, if available.
            </li>
            <li>The Description field (abbreviated)</li>
            <li>The Collection that each record belongs to.</li>
          </ul>
          <p>
            Click on the Summary field or image thumbnail to access individual
            records from the search results.
          </p>
        </div>
      </div>

      <h2 id="ViewRecords">View Records</h2>
      <p>
        Clicking on an item in the search results list will display the
        information held in the Collections catalogue for that record.
      </p>

      <div tabindex="0" class="collapsible">
        <h3>Record View</h3>
        <div>
          <p>The record view includes:</p>
          <ul>
            <li>
              All metadata associated with the record, displayed by field (e.g.
              Title, Collection, Description).
            </li>
            <li>Related items and Collection hierarchy.</li>
            <li>Images associated with the record, if available.</li>
          </ul>

          <div class="boxed text-center">
            <img
              border="0"
              width="569"
              height="325"
              id="Picture 6"
              src="@/assets/tips/image004.png"
              alt="Graphical user interface, text, application&#10;&#10;"
            />
            4. Viewing an individual record in Collections on a desktop machine.
          </div>

          <div class="boxed text-center">
            All occurrences of your search terms identified in the record are
            highlighted in yellow.
          </div>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Image viewer</h3>
        <div>
          <p>
            Any images associated with the record are displayed in the image
            viewer on the right hand side of the page. The image viewer allows
            you to:
          </p>
          <ul>
            <li>Zoom images in and out by clicking on the + and buttons.</li>
            <li>
              Pan images point your cursor anywhere inside the viewer window,
              the cursor will change into a hand icon. Click and hold the mouse
              button, then move the mouse around to pan the image.
            </li>
            <li>
              Navigate to next and previous image when more than one images are
              associated with the record, clicking on the &lt; and &gt; buttons
              at the top of the image viewer will navigate images.
            </li>
          </ul>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Related Items tab</h3>
        <div>
          <p>
            Click on the Related Items tab to view any records, collection areas
            and/or exhibitions associated with the record currently viewed.
          </p>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Collection Hierarchy tab</h3>
        <div>
          <p>
            For Archives &amp; Special Collections records, the Collection
            Hierarchy tab shows each record in the collection's hierarchy with
            summary information about it. Clicking on an item in the collections
            hierarchy list will display the associated record.
          </p>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>On mobile devices</h3>
        <div>
          <p>
            When accessing Collections from a mobile device, you can view Record
            metadata, Related Items, Collection Hierarchy and Images by using
            the respective tabs at the top of the page.
          </p>

          <div class="boxed text-center">
            <img
              src="@/assets/tips/image005.png"
              alt="Graphical user interface, text, application"
            />
            5. Viewing a record on a mobile device. The Record, Related Items,
            Collection Hierarchy and Images tabs at the top are used to navigate
            between the different areas.
          </div>
        </div>
      </div>

      <h2>Refine Search Results</h2>

      <p>
        You can refine search results, by using the filters available on the
        Collections search results page.
      </p>

      <div tabindex="0" class="collapsible">
        <h3>Filters panel</h3>
        <div>
          <p>The Filters panel can be found:</p>
          <ul>
            <li>
              On the left-hand side of the search results page, when accessing
              Collections from a desktop or laptop machine.
            </li>
            <li>
              By clicking on the Funnel icon at the top of the search results
              page, when accessing Collections from a mobile device.
            </li>
          </ul>
          <p>Collections allows you to filter search results by:</p>
          <ul>
            <li>Metadata field</li>
            <li>Collection</li>
            <li>Archival level</li>
            <li>Production Date</li>
            <li>Language</li>
            <li>Modified search terms</li>
            <li>Only records with images</li>
          </ul>
          <p>
            You can use multiple filters simultaneously to narrow down search
            results.
          </p>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Filter by metadata field</h3>
        <div>
          <p>
            Click on the dropdown menu under the "Filter by" label to choose a
            specific metadata field that will be checked against your search
            term(s). By selecting a specific field, only records that contain
            your search term(s) in that field will be displayed.
          </p>
          <p>You can filter search results by:</p>
          <ul>
            <li>All fields (default)</li>
            <li>Title</li>
            <li>Description</li>
            <li>Person</li>
            <li>Place</li>
            <li>Period</li>
            <li>Reference code</li>
            <li>Taxonomy</li>
          </ul>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Filter by collection</h3>
        <div>
          <p>
            Collections brings together the collections of The Hunterian,
            University Archives and Special Collections, which in turn consist
            of a number of collection areas. Filtering by collection allows you
            to narrow your search results down to records from a specific
            collection area. The available options are:
          </p>
          <ul>
            <li>All collections (default)</li>
            <li>Anatomy/Pathology</li>
            <li>Archaeology</li>
            <li>Art</li>
            <li>Historical</li>
            <li>History of Science</li>
            <li>Manuscripts</li>
            <li>Mineralogy/Petrology</li>
            <li>Numismatics</li>
            <li>Palaeontology</li>
            <li>Rare books</li>
            <li>Scottish Business Archive</li>
            <li>Scottish Theatre Archive</li>
            <li>University Archive</li>
            <li>World Cultures</li>
            <li>Zoology</li>
          </ul>
          <p>
            For a description of each collection area, see
            <code>Browse Collection Areas</code>.
          </p>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Filter by archival level</h3>
        <div>
          <p>
            For Archives &amp; Special Collections records which include
            Manuscripts, Rare books, the Scottish Business Archive, the Scottish
            Theatre Archive and the University Archive you can refine search
            results by archival level. Archival level refers to the particular
            point in the collection hierarchy where a record belongs to. The
            available options are:
          </p>
          <ul>
            <li>All levels (default)</li>
            <li>Collection</li>
            <li>Section</li>
            <li>Item</li>
          </ul>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Filter by production date</h3>
        <div>
          <p>
            Enter a date in the format day/month/year, for example 23/11/1821.
            You can also search by only a year or a month and year, but they
            must be in the year or month/year format.
          </p>
          <div class="boxed">
            <p><b>Examples</b></p>
            <ul>
              <li>23/11/1821 will search for that specific date.</li>
              <li>
                11/1821 will search for that month (November) and year (1821).
              </li>
              <li>1821 will search for that year.</li>
            </ul>
          </div>
          <p>
            Dates in the <b>After</b> box are automatically adjusted to the
            beginning of the year if you do not include the day or month.
          </p>
          <div class="boxed">
            <p><b>Examples</b></p>
            <ul>
              <li>1821 is treated as 01/01/1821</li>
              <li>11/1821 is treated as 01/11/1821</li>
            </ul>
          </div>
          <p>
            Dates in the <b>Before</b> box are automatically adjusted to the end
            of the year if you do not include the day or month.
          </p>
          <div class="boxed">
            <p><b>Examples</b></p>
            <ul>
              <li>1821 is treated as 31/12/1821</li>
              <li>11/1821 is treated as 30/11/1821</li>
            </ul>
          </div>
          <p>
            For prehistoric dates, you should append <code>BC</code> to the end
            of the date.
          </p>
          <div class="boxed">
            <p><b>Examples</b></p>
            <ul>
              <li>01/01/52bc is treated as 01/01/-0052</li>
              <li>100BC (in the Before box) is treated as 31/12/-0100</li>
            </ul>
          </div>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Filter by language</h3>
        <div>
          <p>
            You can use the Language filter to refine search results by Language
            of Material for example English, French, German. To filter results
            by language, type the name of the language in the box of the
            Language field and press Enter.
          </p>
          <p>
            You can filter by multiple languages at a time by separating them
            with a comma. The results will include all records where the
            Language of Material is in either or both languages.
          </p>
          <div class="boxed">
            <p><b>Examples</b></p>
            <ul>
              <li>
                Type "French" in the Language filter to see results where the
                Language of material is in French. This will include records
                with multiple languages of material.
              </li>
              <li>
                Type "French, English" in the Language filter to see results
                where the Language of material is either in French, English or
                both.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Modify search terms</h3>
        <div>
          <p>
            You can change the search terms for a search, without losing any
            filters you have specified. You can do this by using the "Search
            again" box at the top of the Filters panel:
          </p>
          <ul>
            <li>
              The "Search again" box will be populated with the term(s) used in
              the current search. Edit the search terms as required including
              the use of
              <code>Search operators</code>.
            </li>
            <li>Click on the Search button or press Enter.</li>
            <li>
              The search results will be updated to show records containing the
              modified/new search terms, while all other filters will remain
              unaltered.
            </li>
          </ul>
        </div>
      </div>
      <div tabindex="0" class="collapsible">
        <h3>Images only</h3>
        <div>
          <p>
            You can narrow your search results to only records with images, by
            clicking on the "Only records with images" checkbox.
          </p>
        </div>
      </div>

      <h2>Browse Collections</h2>

      <p>
        You can browse Collections by Exploring Collections areas and featured
        Exhibitions both can be accessed from the Collections homepage.
      </p>

      <div tabindex="0" class="collapsible">
        <h3>Browse Collection Areas</h3>
        <div>
          <p>
            Find out more about the fascinating collection areas which make up
            the collections of the University of Glasgow and see some of the
            unique objects from these collections. To browse collection areas:
          </p>

          <ul>
            <li>
              Click on the "Explore all Collection areas" button on the
              Collections homepage to access the complete list.
            </li>
            <li>
              Click on a collection area, about which youd like to learn more
              information.
            </li>
            <li>
              Use the "Related Items" tab and image viewer as described in the
              <code>View Records</code> section of this guide.
            </li>
          </ul>
          <div class="boxed">
            <img
              height="300"
              src="@/assets/tips/image006.png"
              alt="Graphical user interface. "
            />
          </div>
        </div>
      </div>

      <div tabindex="0" class="collapsible">
        <h3>Browse Exhibitions</h3>
        <div>
          <p>
            Read more about some of our current and past exhibitions and the
            items which are featured in them. To browse Exhibitions:
          </p>

          <ul>
            <li>
              Click on the "Explore all Collection areas" button on the
              Collections homepage to access the complete list.
            </li>
            <li>
              Click on an Exhibition, about which youd like to learn more
              information.
            </li>
            <li>
              Use the "Related Items" tab and image viewer as described in the
              <code>View Records</code> section of this guide.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "../components/Header.vue";

export default {
  name: "Help",
  components: { Header },
  mounted() {
    document.querySelectorAll(".collapsible").forEach((element) => {
      element.addEventListener("focus", () => {
        element.scrollIntoView(true);
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main h1 {
  margin: 3rem 0 0.5rem 0;
}

main h2 {
  margin: 3rem 0 0.5rem 0;
}

main h3 {
  margin: 2rem 0 0.5rem 0;
  font-size: 1.2rem;
  color: #003865;
}

main p,
main ul {
  margin: 1rem 0 0 0;
}

main img {
  margin: auto;
  display: block;
  max-width: 100%;
  transition: transform 0.5s ease-in-out;
}

main img:hover {
  transform: scale(1.5);
  outline: 0.3rem solid #003865;
}

main table {
  border: none;
  min-width: 100%;
  border-collapse: collapse;
}

main td,
main th {
  padding: 0.5rem;
  min-width: 8rem;
  border: 0.1rem solid #003865;
}

main thead {
  color: #003865;
  background-color: #ddeeff;
  white-space: nowrap;
}

.boxed {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 0.2rem solid #003865;
  background: #ddeeff;
}

.collapsible > :first-child {
  cursor: pointer;
}

.collapsible > :first-child::before {
  font-family: monospace;
  font-size: 1.5rem;
  margin-inline-end: 0.3rem;
  content: ">";
}

.collapsible:focus-within > :first-child::before {
  content: "|";
}

.collapsible > :nth-child(2) {
  overflow: hidden;
  max-height: 0px;
}

.collapsible:focus-within > :nth-child(2) {
  max-height: none;
  overflow: visible;
}

.longer {
  min-width: 16rem;
}

.scrollable {
  overflow: auto;
  margin: 1rem 0;
  border: 0.01rem solid #ddeeff;
}
</style>
