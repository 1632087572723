<!--
Details screen. Displays details of a selected catalogue or narrative object.
@author Brian McKenna
-->
<template>
  <div class="d-flex flex-column vh-100">
    <Header>
      <div v-if="this.$route.query.gdcEvent === 'related_item_view'">
          <!--   <router-link class="text-white mx-2" :to="{name: 'Details', query: {irn: prevIrn, catType: catType, referrer: referrer, narPurpose: narPurpose}}">  -->
          <BIconChevronLeft style="position:relative;top:-0.05rem;" />
          <GoBack /> <!-- want browser style back button when we're going down the related items tree of records -->
          <!--  </router-link> -->
      </div>
      <div v-else>
        <router-link class="text-white mx-2" :to="{path: referrer, query: {narPurpose: narPurpose}}">
          <BIconChevronLeft style="position:relative;top:-0.05rem;" />
          Back
        </router-link>
      </div>
    </Header>
    <main class="container-fluid d-flex flex-column overflow-hidden">
      <!-- Tabs -->
      <div class="row" role="navigation">
        <button class="col-3 col-md-2 tab" :class="{ 'tab-active': activeTab == 1 }" @click="activeTab = 1">
          <span>
            <BIconFileText class="mx-1" />Record
          </span>
        </button>
        <button class="col-3 col-md-2 tab" :class="{ 'tab-active': activeTab == 2 }"
          @click="activeTab = 2; relatedTabClicked()">
          <span>
            <BIconLink45deg class="mx-1" />Related Items
          </span>
        </button>
        <button class="col-3 col-md-2 tab" :class="{ 'tab-active': activeTab == 3 }"
          @click="activeTab = 3; populateHierarchy()">
          <span>
            <BIconBarChartSteps class="mx-1" />Collection Hierarchy
          </span>
        </button>
        <!-- Hide image tab on small screen -->
        <button class="col-3 d-md-none tab" :class="{ 'tab-active': activeTab == 4 }" @click="activeTab = 4">
          <span>
            <BIconImage class="mx-1" />Images
          </span>
        </button>
        <!-- Show image info on large screen -->
        <div class="col d-none d-md-flex" v-if="imagesChecked && !imagesExist">
          There are no images for this record.
        </div>
        <div class="col d-none d-md-flex" v-if="imagesChecked && imagesExist">
          <div class="align-self-center">Image {{ currentImage + 1 }} of {{ images.length }}</div>
        </div>
      </div>
      <!-- Content -->
      <div class="row overflow-hidden">
        <!-- Data -->
        <div class="col col-md-6 border-right border-bottom p-3 h-100 overflow-auto"
          v-bind:class="{ 'd-none': activeTab != 1 }">
          <Spinner ref="manifestSpinner" text="For records with very large images this may take up to a minute." />
          <dl v-if="manifest != null">
            <div v-if="catType != 'N'">
              <dt>Summary</dt>
              <dd v-html="manifest.description"></dd>
            </div>
            <div v-for="item in manifest.metadata" :key="item.value">
              <dt>{{ item.label }}</dt>
              <dd v-html="formatDataVal(item.value, item.label)"></dd>
            </div>
          </dl>
        </div>
        <!-- Related -->
        <div :class="{ 'd-none': activeTab != 2 }"
          class="col col-md-6 border-right border-bottom p-3 h-100 overflow-auto">
          <Spinner ref="relatedSpinner" />
          <div class="card mb-3" v-for="item in related" :key="item.imageUrl">
            <button class="related" @click="loadRelated(item.catType, item.irn)">
              <div class="thumbnail-container">
                <img v-if="item.imageUrl" :src="item.imageUrl" :alt="item.imageAltText">
                <div v-if="!item.imageUrl" class="no-image d-flex">No image available</div>
              </div>
              <div>
                <div v-if="item.catType === 'N'">
                  <strong>{{ item.narPurpose }}</strong>
                  {{ item.title }}
                </div>
                <div v-if="item.catType === 'C'">{{ item.summaryData }}</div>
              </div>
            </button>
          </div>
          <button v-if="relatedLastBatch == 10" type="button" class="btn btn-secondary btn-block" @click="getRelated">
            Show more related items
            <span v-if="nextRelatedLoading" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
          </button>
          <div v-if="relatedChecked && !relatedExists">
            This record has no related objects or topics.
          </div>
        </div>
        <!-- Hierarchy -->
        <div id="scrollingDiv" class="col col-md-6 border-right border-bottom p-3 h-100 overflow-auto"
          v-bind:class="{ 'd-none': activeTab != 3 }">
          <Spinner ref="hierarchySpinner" />
          <Hierarchy :nodes="hierarchyNodes" :selected="irn" class="pl-0" @showNode="hierarchyScroll = true" />
          <div v-if="hierarchyChecked && !hierarchyExists">
            This record is not part of a hierarchy.
          </div>
        </div>
        <!-- Images -->
        <div class="col h-100 overflow-auto" v-bind:class="{ 'd-none': (activeTab != 4 && !fullScreen) }">
          <div class="d-md-none" v-if="imagesChecked && !imagesExist">
            There are no images for this record.
          </div>
          <div class="d-md-none" v-if="imagesChecked && imagesExist">
            Image {{ currentImage + 1 }} of {{ images.length }}
          </div>
          <!-- Image nav for small screens -->
          <div class="row d-md-none" v-if="imagesChecked && imagesExist">
            <div class="col-6 link image-viewer-nav text-left pt-1 pb-1" @click="prevImage()">
              <BIconChevronCompactLeft />
            </div>
            <div class="col-6 link image-viewer-nav text-right pt-1 pb-1" @click="nextImage()">
              <BIconChevronCompactRight />
            </div>
          </div>
          <div class="row" v-if="imagesChecked && imagesExist">
            <div class="col-1 link image-viewer-nav d-none d-md-inline-block" @click="prevImage()">
              <BIconChevronCompactLeft />
            </div>
            <div class="col-12 col-md-10 p-0">
              <div id="imageViewer"></div>
            </div>
            <div class="col-1 link image-viewer-nav text-right d-none d-md-inline-block" @click="nextImage()">
              <BIconChevronCompactRight />
            </div>
          </div>
          <Spinner ref="imageSpinner" text="For very large images this may take a few seconds." />
          <dl v-if="imagesChecked && imagesExist && imageViewer != null">
            <div v-for="item in images[currentImage].metadata" :key="item.value">
              <dt>{{ item.label }}</dt>
              <dd v-html="formatDataVal(item.value, item.label)"></dd>
            </div>
            <dt v-if="images[currentImage].description">Description</dt>
            <dd v-if="images[currentImage].description" v-html="images[currentImage].description"></dd>
          </dl>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Hierarchy from '@/components/Hierarchy.vue'
import Menu from '@/components/Menu.vue'
import Spinner from '@/components/Spinner.vue'
import GoBack from '@/components/GoBack.vue'
import axios from 'axios'
import {
  BIconBarChartSteps, BIconCaretLeftFill,
  BIconChevronCompactLeft, BIconChevronCompactRight,
  BIconChevronLeft,
  BIconFileText, BIconImage, BIconLink45deg
} from 'bootstrap-icons-vue'
import $ from 'jquery'
import 'leaflet'
import 'leaflet-css'
import 'leaflet-iiif'
import Mark from 'mark.js'
import Header from '../components/Header.vue'

// Bootstrap's md width that we use to go from small to large screen mode.
const BS_MD = 768

export default {
  name: 'Details',
  components: {
    BIconFileText,
    BIconBarChartSteps,
    BIconImage,
    BIconChevronCompactLeft,
    BIconChevronCompactRight,
    Spinner,
    GoBack,
    BIconLink45deg,
    Menu,
    Hierarchy,
    Header,
    BIconCaretLeftFill,
    BIconChevronLeft
  },
  mixins: [],
  data() {
    return {
      catType: null,
      irn: null,
      narPurpose: null,
      manifest: null,
      relatedChecked: false,
      relatedExists: false,
      related: [],
      relatedFirstResult: 0,
      relatedLastBatch: 0,
      nextRelatedLoading: false,
      hierarchyNodes: [],
      hierarchyChecked: false,
      hierarchyExists: false,
      hierarchyScroll: false,
      imagesChecked: false,
      imagesExist: false,
      imageViewer: null,
      imageLayer: null,
      images: [],
      currentImage: -1,
      activeTab: 1,
      fullScreen: window.innerWidth >= BS_MD,
      referrer: '/',
      prevIrn: null
    }
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      //console.log('route changed from ' + from.fullPath + ' to ' + to.fullPath)
      if (to.name === 'Details') {
        this.unloadData()
        this.loadData()
      }
    },
    manifest: function () {
      const query = this.$route.query.q

      if (query) {
        setTimeout(() => {
          const marker = new Mark('#app')

          marker.unmark()
          marker.mark(query.replaceAll(/[^\w\s]+/g, ''))
        }, 200)
      }
    }
  },
  updated() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      if (this.imagesChecked && this.imagesExist && this.imageViewer == null &&
        (this.fullScreen || this.activeTab === 4)) {
        // Initialise image viewer.
        this.imageViewer = L.map('imageViewer', {
          center: [0, 0],
          crs: L.CRS.Simple,
          zoom: 0,
          tileSize: 500,
          setMaxBounds: true,
          attributionControl: false
        })
        this.currentImage = 0
        this.loadImage()
      }
      if (this.activeTab === 3 && this.hierarchyScroll) {
        // Scroll to current record in the hierarchy. See @showNode event handler above.
        // This is emitted from the Hierarchy component for the current record.
        const currentRecord = document.getElementById(this.irn)
        const topPos = currentRecord.offsetTop

        document.getElementById('scrollingDiv').scrollTop = topPos - 16
        this.hierarchyScroll = false
      }
    })
  },
  methods: {
    // Fetch IIIF manifest.json for requested irn.
    loadData() {
      this.$refs.manifestSpinner.spin()
      const catType = this.$route.query.catType
      const irn = this.$route.query.irn
      const narPurpose = this.$route.query.narPurpose
      let gdcEvent = this.$route.query.gdcEvent
      if (!gdcEvent) {
        // gdcEvent is passed when a related or hierarchy is clicked.
        // If it's not supplied, the request is coming from a search or bookmarked link.
        // Default to item_view for these.
        gdcEvent = 'item_view'
      }
      this.catType = catType
      this.irn = irn
      this.narPurpose = narPurpose
      axios.get(
        process.env.VUE_APP_WS_URL + '/gdc/manifest/' + catType + '/' + irn
      ).then(response => {
        this.manifest = response.data
        this.storeImages()
        this.sendEventToGoogleAnalytics(gdcEvent)
      }).finally(() => {
        this.$refs.manifestSpinner.stop()
      })
    },
    // Send event in Google Analytics.
    // Events and data are in google_analytics_naming_notation.
    sendEventToGoogleAnalytics(gdcEvent) {
      const data = { cat_type: this.catType, irn: this.irn }
      if (this.manifest.metadata) {
        if (this.catType === 'N') {
          data.title = this.getMetadataValue('Title')
        } else {
          data.department = this.getMetadataValue('Department')
          data.collection = this.getMetadataValue('Collection')
          data.object_number = this.getMetadataValue('Object Number')
          data.reference_code = this.getMetadataValue('Reference Code')
        }
      }
      this.$gtag.event(gdcEvent, data)
    },
    // Return a metadata value for the specified label
    getMetadataValue(label) {
      let value = null
      for (const item of this.manifest.metadata) {
        if (item.label === label) {
          value = item.value
          break
        }
      }
      return value
    },
    // Resets the view's data.
    unloadData() {
      this.catType = null
      this.irn = null
      this.manifest = null
      this.relatedChecked = false
      this.relatedExists = false
      this.related = []
      this.relatedFirstResult = 0
      this.relatedLastBatch = 0
      this.nextRelatedLoading = false
      this.hierarchyNodes = []
      this.hierarchyChecked = false
      this.hierarchyExists = false
      this.hierarchyScroll = false
      this.imagesChecked = false
      this.imagesExist = false
      this.images = []
      this.currentImage = -1
      this.activeTab = 1
      // Clear the image viewer.
      if (this.imageViewer) {
        if (this.imageLayer) {
          try {
            this.imageViewer.removeLayer(this.imageLayer)
          } catch (err) {
            console.log(err)
          }
          this.imageLayer = null
        }
        try {
          this.imageViewer.remove()
        } catch (err) {
          console.log(err)
        }
        this.imageViewer = null
      }
    },
    // Parses the manifest for info.json image URLs and stores to easily load in the image viewer.
    storeImages() {
      this.manifest.sequences?.forEach(sequence => {
        sequence.canvases?.forEach(canvas => {
          canvas.images?.forEach(image => {
            const imageInfo = {
              description: canvas.description
            }

            if (canvas.metadata) {
              imageInfo.metadata = canvas.metadata

              if (canvas.metadata.filter((metadatum) => {
                return metadatum.label === 'Title' && metadatum.value === imageInfo.description
              }).length) {
                imageInfo.description = undefined
              }
            }

            const url = image.resource.service['@id'] + '/info.json'

            imageInfo.url = url
            this.images.push(imageInfo)
          })
        })
      })

      this.imagesChecked = true
      this.imagesExist = this.images.length > 0
    },
    // Fetches the first batch of related items the first time the related tab is clicked.
    relatedTabClicked() {
      if (!this.relatedChecked) {
        this.getRelated()
      }
    },
    // Gets the next batch of related items.
    getRelated() {
      this.$refs.relatedSpinner.spin()
      this.nextRelatedLoading = true

      axios.get(process.env.VUE_APP_WS_URL + '/gdc/search/related', {
        params: {
          catType: this.catType,
          irn: this.irn,
          firstResult: this.relatedFirstResult
        }
      }).then(response => {
        this.relatedExists = response.data != null && response.data !== '' && response.data.length > 0

        if (this.relatedExists) {
          this.related = this.related.concat(response.data)
          this.relatedLastBatch = response.data.length

          if (this.relatedLastBatch === 10) {
            this.relatedFirstResult += 10
          }
        } else {
          this.relatedLastBatch = 0
        }
      }).catch(() => {
        this.relatedExists = false
      }).finally(() => {
        this.relatedChecked = true
        this.nextRelatedLoading = false
        this.$refs.relatedSpinner.stop()
      })
    },
    // Loads related data in this screen.
    loadRelated(catType, irn) {
      this.$router.push({
        path: 'details', query: {
          catType: catType, irn: irn,
          referrer: this.referrer,
          narPurpose: this.narPurpose,
          gdcEvent: 'related_item_view'
        }
      })
    },
    // Fetches the object hierarchy from the server and displays it.
    populateHierarchy() {
      if (this.hierarchyChecked) {
        return
      }

      this.$refs.hierarchySpinner.spin()
      axios.get(process.env.VUE_APP_WS_URL + '/gdc/search/hierarchy', {
        params:
        {
          catType: this.catType,
          irn: this.irn,
          department: this.getMetadataValue('Department') + ''
        }
      }).then(response => {
        this.hierarchyExists = response.data != null && response.data !== ''

        if (this.hierarchyExists) {
          const root = this.getRoot(response.data)
          this.hierarchyNodes = [root]
          this.addChildren(root, response.data)
        }
      }).catch(() => {
        this.hierarchyExists = false
      }).finally(() => {
        this.hierarchyChecked = true
        this.$refs.hierarchySpinner.stop()
      })
    },
    // Gets the root node of the hierarchy.
    getRoot(responseData) {
      for (const item of responseData) {
        if (item.parentId == null) {
          return {
            id: item.irn,
            text: item.text,
            children: [],
            emuId: item.id,
            catType: item.catType,
            root: true
          }
        }
      }

      return null
    },
    // Recursively builds the hierarchy.
    addChildren(node, responseData) {
      responseData.forEach(item => {
        if (item.parentId === node.emuId) {
          const child = { id: item.irn, text: item.text, children: [], emuId: item.id, catType: item.catType }
          this.addChildren(child, responseData)
          node.children.push(child)
        }
      })
    },
    // Loads the image into Leaflet
    loadImage() {
      this.$refs.imageSpinner.spin()

      const url = this.images[this.currentImage].url
      const alt = this.images[this.currentImage].description

      if (this.imageLayer != null) {
        this.imageViewer.removeLayer(this.imageLayer)
      }

      this.imageLayer = L.tileLayer.iiif(url, {
        tileSize: 512
      }).addTo(this.imageViewer)

      this.imageLayer.on('load', () => {
        this.imageLayer.off('load')
        this.$refs.imageSpinner.stop()
      })

      this.imageLayer.on('tileload', (event) => {
        event.tile.setAttribute('alt', alt)
      })
    },
    // Formats data values for display.
    formatDataVal(val, fieldName) {
      // any of these matching fields need to be bulleted - Note the text needs to match the @IiifMetaData name in Catalogue
      if ($.inArray(fieldName, ['Bibliography', 'Subjects', 'Corporate Names', 'Personal Names', 'Family Names', 'Geographic Names', 'Name of Creators', 'Language of Material', 'Producers', 'Production Dates', 'Production Place', 'Provenance', 'Dimensions']) >= 0) {
        val = '&bullet; ' + val
        val = val.replace(/\|/g, '<br>&bullet; ')
        return val
      }
      val = '' + val
      val = val.replace(/\|/g, ', ')
      // they use an odd formatting in emu so we need to convert to proper html
      val = val.replaceAll('<list>', '<ul style="list-style-type:none;">')
      val = val.replaceAll('</list>', '</ul>')
      val = val.replaceAll('<item>', '<li>')
      val = val.replaceAll('</item>', '</li>')
      return val
    },
    // Navigates to a loads the next image.
    nextImage() {
      if (this.currentImage < this.images.length - 1) {
        this.currentImage += 1
        this.loadImage()
      }
    },
    // Navigates to a loads the previous image.
    prevImage() {
      if (this.currentImage > 0) {
        this.currentImage -= 1
        this.loadImage()
      }
    }
  },
  mounted() {
    // Load data
    this.loadData()

    // Show and hide screen elements when switching between small and full screen modes.
    window.onresize = () => {
      const newWidth = window.innerWidth
      if (!this.fullScreen && newWidth >= BS_MD && this.activeTab === 4) {
        // Switching to full screen when images tab is active so revert to data as active tab.
        this.activeTab = 1
      }
      this.fullScreen = newWidth >= BS_MD
    }

    if (this.$route.query.referrer) {
      this.referrer = this.$route.query.referrer
    }
    if (this.$route.query.irn) {
      this.prevIrn = this.$route.query.irn
    }
  },
  unmounted() {
    this.unloadData()
  }
}
</script>

<!-- Place scoped component only. -->
<style scoped>
.tab h2 {
  font-size: 1rem !important;
  font-weight: 400 !important;
  margin-bottom: 0;
}

[class*="col-"] {
  background-color: #fff;
  border: 0;
}

.row {
  margin-top: 0 !important;
  margin-bottom: 0;
}

.tab {
  padding: 1rem;
  display: flex;
  color: var(--primary);
  overflow: hidden;
  justify-content: center;
  background-color: #F7F7F7;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.related {
  gap: 0.5rem;
  border: none;
  display: flex;
  padding: 0.5rem;
  background: none;
  text-align: initial;
  align-items: center;
  justify-items: center;
}

.related *:first-child {
  flex-shrink: 0;
}

.related:hover,
.tab:hover {
  text-decoration: underline;
}

.tab-active {
  background-color: #fff;
  border-bottom: 0;
}

.link {
  cursor: pointer;
}

#imageViewer {
  height: 512px;
}

.leaflet-container {
  background-color: #4f5961 !important;
}

.leaflet-container .leaflet-control-attribution {
  box-shadow: 0 0 0;
  background-color: transparent;
}

.leaflet-control-attribution a {
  color: #fff !important;
}

.image-viewer-nav {
  background-color: #4f5961;
  color: #fff;
}
</style>
