<!--
Displays project history.
-->
<template>
  <Header />
  <main class="container-fluid py-4">
    Placeholder for project history.
  </main>
</template>

<script>
import Header from '../components/Header.vue';

export default {
  name: "History",
  components: { Header }
}
</script>
